import * as Sentry from "@sentry/vue";
import type { CaptureContext, SeverityLevel } from "@sentry/types";
// @ts-ignore
import type { ExclusiveEventHintOrCaptureContext } from "@sentry/core/build/types/utils/prepareEvent";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentryDsn, sentryEnabled, appEnv, releaseVersion },
  } = useRuntimeConfig();

  if (!sentryDsn || sentryEnabled === "false") {
    return {
      provide: {
        sentry: {
          setContext: (
            _name: string,
            _context: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              [key: string]: any;
            } | null,
          ) => {},
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          captureException: (_exception: any, _hint?: ExclusiveEventHintOrCaptureContext) => {},
          captureMessage: (_message: string, _captureContext?: CaptureContext | SeverityLevel) => {},
        },
      },
    };
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDsn as string,
    environment: appEnv as string,
    release: releaseVersion as string,
    integrations: [],
  });

  // The functions seem to be shifted under a "default" key after we build our project.
  // TODO: https://github.com/getsentry/sentry-javascript/issues/7441
  // I wrote a comment under this thread, but might need to create a separate issue if no one responds.
  return {
    provide: {
      sentry: getSentryInstance(Sentry),
    },
  };
});

type SentryInstance = typeof Sentry & { default?: typeof Sentry };

function getSentryInstance(sentry: SentryInstance) {
  if (sentry?.default?.captureMessage) {
    return sentry.default;
  }
  return sentry;
}
