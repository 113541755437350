import type { FetchError } from "ofetch";
import type { Sex } from "~/types/onboarding/registrationParams";
import type { GoalTypeAbbr } from "~/types/onboarding/onboardingGoal";

export interface TrackingMetadata {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  partner?: string;
  internal_transaction_id?: string;
}

export interface EventMetadataProperties {
  mediasource: string;
  channel: string;
  campaignid: string;
  adsetid: string;
  adid: string;
  heyflow_id: string;
}

export interface EventProperties extends TrackingMetadata {}

interface BiTrackingDate {
  isoDateTime: string;
}

export interface EventParams {
  properties?: EventProperties;
}

export interface ActionEventParams extends EventParams {
  name: string;
  selection: Array<string>;
  screenType: string;
}

export interface ImpressionEventParams extends EventParams {
  name: string;
}

export interface PurchaseEventParams extends EventParams {
  sku: string;
  period: string;
  currency: string;
  gateway: "stripe";
  amountCustomerFacingCurrency: number;
}

export enum BiTrackingEventType {
  IMPRESSION = "Impression",
  PURCHASE = "Purchase",
  ACTION = "Action",
}

export interface BiTrackingEvent {
  type: BiTrackingEventType;
  sessionId: string;
  date: BiTrackingDate;
  properties: string;
}

export interface ActionEvent extends BiTrackingEvent {
  type: BiTrackingEventType.ACTION;
  name: string;
}

export interface ImpressionEvent extends BiTrackingEvent {
  type: BiTrackingEventType.IMPRESSION;
  name: string;
}

export interface PurchaseEvent extends BiTrackingEvent {
  type: BiTrackingEventType.PURCHASE;
  duration: {
    isoPeriod: string;
  };
  sku: string;
  currency: string;
  gateway: "stripe";
  amountCustomerFacingCurrency: number;
}

export interface TrackingAbTests {
  [key: string]: string;
}

export interface BiTrackingHeader {
  age: number | null;
  platform: string | null;
  language: string | null;
  country: string | null;
  sex: Sex | null;
  overallGoal: GoalTypeAbbr | null;
  weightGoal: number | null;
  weightCurrent: number | null;
  bodyMassIndex: number | null;
  userUUID: string | null;
  abTests: TrackingAbTests;
  properties: string;
}

export type BiTrackingEvents = Array<ImpressionEvent | PurchaseEvent | ActionEvent>;

export type BiTrackingRequestBody = Array<{
  header: Partial<BiTrackingHeader>;
  events: BiTrackingEvents;
}>;

export interface BiTrackingResponseData extends FetchError {
  message: string;
  timestamp?: string;
  status?: number;
  error?: string;
  path?: string;
}
