import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import {
  ActivityDegreeSchema,
  DietSchema,
  type RegistrationParams,
  SexSchema,
  UnitMassSchema,
} from "~/types/onboarding/registrationParams";
import { type GoalTypeAbbr, GoalTypeAbbrSchema } from "~/types/onboarding/onboardingGoal";
import appConfig from "~/app.config";
import type { QueryParameters } from "~/composables/useQueryParameters";
import { useExtractQueryParams } from "~/composables";

type InitialParameterNames = "userUuid" | "activityDegree" | "diet" | "country" | "password" | "incomplete";
type InitialRegistrationParams = Pick<RegistrationParams, InitialParameterNames>;
type PartialRegistrationParams = Omit<Partial<RegistrationParams>, InitialParameterNames> & InitialRegistrationParams;

export const useOnboardingStore = defineStore(
  "onboarding",
  () => {
    const { getCountry } = useCountry();
    const registrationParams = ref<PartialRegistrationParams>(initialRegistrationParams());
    const foodName = ref<string>("");
    const multiChoiceSelectedOptions = ref<{ [key: string]: string[] }>({});

    function $reset(): void {
      registrationParams.value = initialRegistrationParams();
      foodName.value = "";
    }

    function initialRegistrationParams(): InitialRegistrationParams {
      return {
        userUuid: uuidv4(),
        activityDegree: "moderate",
        diet: null,
        country: getCountry(),
        password: uuidv4(),
        incomplete: false,
      };
    }

    function updateRegistrationParams(params: Partial<RegistrationParams>) {
      registrationParams.value = { ...registrationParams.value, ...params };
      determineUserGoal();
    }

    function determineUserGoal() {
      const goal = getGoal();

      if (goal) {
        registrationParams.value.goal = goal;
      }
    }

    function getGoal(): GoalTypeAbbr | undefined {
      const { weightGoal, startWeight, reason } = registrationParams.value;

      if (!weightGoal || !startWeight) return undefined;

      if (weightGoal < startWeight) return "lose";
      if (weightGoal > startWeight) return reason === "build_muscle" ? "build_muscle" : "gain";

      return "maintain";
    }

    async function skipOnboarding() {
      registrationParams.value = {
        ...registrationParams.value,
        goal: "lose",
        unitMass: "kg",
        weightGoal: 60,
        startWeight: 70,
        unitLength: "cm",
        bodyHeight: 170,
        sex: "female",
        dateOfBirth: "2000-01-01",
        incomplete: true,
      };

      await navigateTo(`${useRuntimeConfig().app.baseURL}/onboarding/checkout`, { external: true });
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        foodName.value = queryParameters.getAndRemove("food_name") ?? "";
      }

      if (!queryParameters.isNewFlow()) return;

      $reset();

      const { defaultUnitMass } = useNuxtApp().$i18n;
      const extractedParams: Partial<RegistrationParams> = {
        userUuid: queryParameters.getAndRemove("user_uuid"),
        reason: queryParameters.parseAndRemove("goal", GoalTypeAbbrSchema),
        unitMass: queryParameters.parseAndRemove("weightUnit", UnitMassSchema),
        weightGoal: useExtractQueryParams().extractWeight(
          queryParameters.getAndRemove("goalWeight"),
          queryParameters.parseAndRemove("weightUnit", UnitMassSchema) ?? defaultUnitMass.value,
        ),
        startWeight: useExtractQueryParams().extractWeight(
          queryParameters.getAndRemove("currentWeight"),
          queryParameters.parseAndRemove("weightUnit", UnitMassSchema) ?? defaultUnitMass.value,
        ),
        sex: queryParameters.parseAndRemove("gender", SexSchema),
        bodyHeight: useExtractQueryParams().extractBodyHeight(queryParameters),
        dateOfBirth: useExtractQueryParams().extractDob(queryParameters),
        diet: queryParameters.parseAndRemove("diet", DietSchema),
        activityDegree: queryParameters.parseAndRemove("activity_degree", ActivityDegreeSchema),
        unitLength: queryParameters.getAndRemove("height") ? "cm" : "in",
        incomplete: queryParameters.getAndRemove("incomplete") === "1",
        password: uuidv4(),
      };

      const cleanedObject = Object.fromEntries(
        Object.entries(extractedParams).filter(([, value]) => value !== undefined),
      );

      updateRegistrationParams(cleanedObject);
    }

    return {
      foodName,
      registrationParams,
      multiChoiceSelectedOptions,
      $reset,
      skipOnboarding,
      updateRegistrationParams,
      handleQueryParameters,
    };
  },
  {
    persist: [
      {
        paths: ["multiChoiceSelectedOptions"],
        storage: useExpiringLocalStorage(HoursToSeconds(appConfig.cookieMaxAgeH)),
      },
      {
        paths: ["registrationParams", "foodName"],
        storage: persistedState.cookiesWithOptions({
          maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
        }),
      },
    ],
  },
);
