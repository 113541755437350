import type { AbTests } from "~/types/abTest";

export const testConfig = {
  green_paywall_v2: {
    variants: [
      {
        variant: "green_paywall_v2_baseline",
        percentage: 50,
      },
      {
        variant: "green_paywall_v2_variant",
        percentage: 50,
      },
    ],
  },
} as const satisfies AbTests;

const abTestEnabledStatus: Record<keyof typeof testConfig, boolean> = {
  green_paywall_v2: true,
};

export const enabledAbTests = Object.keys(abTestEnabledStatus).filter(
  (key) => abTestEnabledStatus[key as keyof typeof abTestEnabledStatus],
);
