export default defineNuxtRouteMiddleware(() => {
  const headers = useRequestHeaders();

  if (
    import.meta.client ||
    useRuntimeConfig().public.abTestsEnabled === "false" ||
    headers["user-agent"] === "playwright-test"
  ) {
    return;
  }

  useDefaultAbTest().applyUserAbTestGroups();
});
