<script lang="ts" setup>
import AppAlertDanger from "~/components/app/AppAlertDanger.vue";

interface Error {
  url: string;
  statusCode: number;
  statusMessage: string;
  message: string;
  description: string;
}

const props = defineProps<{
  error: Error;
}>();

const { t, locale } = useNuxtApp().$i18n;
const generalErrorMessage: string = t("app.general.error.general", ["https://help.yazio.com/hc/" + locale.value]);

const errorMessage: string = props.error.message ?? generalErrorMessage;
</script>

<template>
  <NuxtLayout>
    <div class="container my-5">
      <h1 v-if="!errorMessage" class="!tw-text-center">
        {{ error.statusCode }}
      </h1>
      <AppAlertDanger>
        <div class="py-2" v-html="errorMessage" />
      </AppAlertDanger>
    </div>
  </NuxtLayout>
</template>
