import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import useDirectives_W0tXrzvhuP from "/app/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "/app/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import plugin_8SbxDRbG6Y from "/app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  plugin_8SbxDRbG6Y,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE
]