import type { QueryParameters } from "~/composables/useQueryParameters";

export function useLocale() {
  async function handleQueryParameters(queryParameters: QueryParameters) {
    const { setLocale, locales } = useNuxtApp().$i18n;

    if (queryParameters.has("lang")) {
      const lowerCaseLangParameter = queryParameters.getAndRemove("lang").toLocaleLowerCase();
      const locale = locales.value.find((l) => l.code === lowerCaseLangParameter);

      await setLocale(locale?.code ?? "en");
    }
  }

  return {
    handleQueryParameters,
  };
}
