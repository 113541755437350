<script lang="ts" setup>
import EventTrackingHandler from "~/components/EventTrackingHandler.vue";

useGTM().install();
</script>

<template>
  <NuxtLayout>
    <ClientOnly>
      <EventTrackingHandler />
    </ClientOnly>
    <NuxtPage />
  </NuxtLayout>
</template>
